import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  UIEvent,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from 'react'

import { useContentCardContext } from './hooks/useContentCardContext'
import { StyledContentCardBody } from './styles'

export const ContentCardBody = forwardRef<
  ElementRef<typeof StyledContentCardBody>,
  ComponentPropsWithoutRef<typeof StyledContentCardBody>
>(({ children, ...props }, ref) => {
  const { setContentOverflow, contentOverflow } = useContentCardContext()
  const internalRef = useRef<HTMLDivElement>(null)

  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(
    ref,
    () => internalRef.current,
    [internalRef]
  )

  useLayoutEffect(() => {
    if (internalRef.current) {
      const scrollable =
        internalRef.current.scrollHeight > internalRef.current.clientHeight
      setContentOverflow(scrollable)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onScroll = (event: UIEvent<HTMLElement>) => {
    const target = event.target as HTMLElement
    const scrolledToBottom =
      target.scrollHeight - target.scrollTop === target.clientHeight
    if (scrolledToBottom && contentOverflow) {
      setContentOverflow(false)
    } else if (!contentOverflow) {
      setContentOverflow(true)
    }
  }

  return (
    <StyledContentCardBody {...props} onScroll={onScroll} ref={internalRef}>
      {children}
    </StyledContentCardBody>
  )
})

ContentCardBody.displayName = 'ContentCardBody'
