import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter'
import { z } from 'zod'

const schema = z.object({
  agreementType: fallback(z.enum(['user', 'essential']), 'user').default(
    'user'
  ),
})

export const termsSearchSchema = zodSearchValidator(schema)
export type TermsSearchSchema = z.infer<typeof schema>
