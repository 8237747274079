import { useSearch } from '@tanstack/react-router'

import {
  RootSearchParams,
  rootSearchParams,
  RootSearchSchema,
} from '@/lib/searchSchemas/rootSearchSchema'

/**
 * Tanstack router returns all query params, this allows us to strip any non root params
 */
export const useRootSearch = () => {
  const search = useSearch({
    from: '__root__',
    select: search => {
      const entries = Object.entries(search)
      const filteredEntries = entries.filter(([key]) =>
        rootSearchParams.includes(key as RootSearchParams)
      )
      return Object.fromEntries(filteredEntries) as RootSearchSchema
    },
  })
  return search
}
