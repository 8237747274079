import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter'
import { z } from 'zod'

const schema = z.object({
  type: fallback(z.enum(['BRANCH', 'BULLHORN', 'PAYLOCITY']), 'BRANCH').default(
    'BRANCH'
  ),
  org_id: z.coerce.string().optional(),
  external_org_id: z.coerce.string().optional(),
  external_worker_id: z.coerce.string().optional(),
  worker_id: z.coerce.string().optional(),
})

export const rootSearchParams = schema.keyof()._def.values
export type RootSearchParams = (typeof rootSearchParams)[number]

export const rootSearchSchema = zodSearchValidator(schema)
export type RootSearchSchema = z.infer<typeof schema>
