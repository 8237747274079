import { QueryClient } from '@tanstack/react-query'
import { isAxiosError } from 'axios'

import { queryCache } from './queryCache'

export const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (isAxiosError(error) && error.response?.status === 404) {
          return false
        }
        return failureCount < 3
      },
    },
  },
})
