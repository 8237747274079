import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { FC } from 'react'

import { GlobalAlert } from '../globalAlert'
import { Toaster } from '../toaster'
import { AppBody } from './AppBody'
import { AppFooter } from './AppFooter'
import { AppHeader } from './AppHeader'
import { StyledAppLayout } from './styles'

export const AppLayout: FC = () => {
  return (
    <>
      <Toaster />
      <GlobalAlert />
      <StyledAppLayout>
        <AppHeader />
        <AppBody />
        <AppFooter />
      </StyledAppLayout>
      <ReactQueryDevtools buttonPosition="bottom-left" initialIsOpen={false} />
      {process.env.NODE_ENV !== 'production' && (
        <TanStackRouterDevtools position="bottom-right" initialIsOpen={false} />
      )}
    </>
  )
}
