import Mixpanel from 'mixpanel-browser'

import { Domain, EventProperties } from '@/types/analytics'

export const sendAnalyticsEvent = (
  domain: Domain,
  event: string,
  properties?: EventProperties
) => {
  const eventName = `${domain}|${event}`
  if (import.meta.env.VITE_MIXPANEL_TOKEN) {
    Mixpanel.track(eventName, properties)
  } else {
    // eslint-disable-next-line no-console
    console.log('Tracking event: ', eventName, properties)
  }
}

export const initializeAnalytics = () => {
  if (import.meta.env.VITE_MIXPANEL_TOKEN) {
    Mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
      loaded: mixpanel => {
        // use Mixpanel's device token for device identification
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        localStorage.setItem('DEVICE_ID', mixpanel.get_property('$device_id'))
      },
    })
  }
}

export const identifyAnalyticsUser = (userId: string) => {
  if (import.meta.env.VITE_MIXPANEL_TOKEN) {
    Mixpanel.identify(userId)
  } else {
    // eslint-disable-next-line no-console
    console.log(`Registering alias ${userId}`)
  }
}
