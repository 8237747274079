import { BranchLogo } from '@branch-messenger/willow-ui/icons'
import { FC } from 'react'

import { StyledAppHeaderContainer } from './styles'

export const AppHeader: FC = () => {
  return (
    <StyledAppHeaderContainer>
      <BranchLogo height={40} width={'100%'} />
    </StyledAppHeaderContainer>
  )
}
