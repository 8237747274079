import { useContext } from 'react'

import { ContentCardContext } from '../ContentCard'

export function useContentCardContext() {
  const context = useContext(ContentCardContext)
  if (!context) {
    throw new Error('useContentCardContext must be used within an ContentCard')
  }
  return context
}
