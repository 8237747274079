import { NavigateOptions } from '@tanstack/react-router'

import { useRootSearch } from './useRootSearch'

export const useGetPersistedSearch = () => {
  const rootSearch = useRootSearch()
  const getPersistedSearch = (search: NavigateOptions['search']) => {
    const searchIsObject = typeof search === 'object'
    return searchIsObject ? { ...rootSearch, ...search } : true
  }
  return getPersistedSearch
}
