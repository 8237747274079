import { Typography } from '@branch-messenger/willow-ui'
import { FC } from 'react'

import { StyledAppFooterContainer } from './styles'

export const AppFooter: FC = () => {
  return (
    <StyledAppFooterContainer $display="flex" $justify="center">
      <Typography $size="xs" $color="textMuted">
        © Branch {new Date().getFullYear()} - All rights reserved.
      </Typography>
    </StyledAppFooterContainer>
  )
}
