import { Box, Typography } from '@branch-messenger/willow-ui'
import { ElementRef, forwardRef, ReactNode } from 'react'

import { StyledContentCardHeading } from './styles'

type Props = {
  children: ReactNode
  subtitle?: ReactNode
}

export const ContentCardHeading = forwardRef<
  ElementRef<typeof Typography>,
  Props
>(({ subtitle, children }, ref) => {
  return (
    <Box $display="flex" $direction="column" $align="stretch" $gap={2}>
      <StyledContentCardHeading ref={ref}>{children}</StyledContentCardHeading>
      {subtitle}
    </Box>
  )
})

ContentCardHeading.displayName = 'ContentCardHeading'
