export const isSafari = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  return (
    userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1
  )
}

const supportStorageAccessApi = () => {
  return 'hasStorageAccess' in document && 'requestStorageAccess' in document
}

const isIniFrame = () => {
  if (window.location !== window.parent.location) {
    return true
  }
  return false
}

export const hasStorageAccess = () => {
  return document.hasStorageAccess()
}

export const requestStorageAccess = () => {
  return document.requestStorageAccess()
}

export const requiresStoragePermissions = () => {
  return isSafari() && supportStorageAccessApi() && isIniFrame()
}
