import { Box, Icon, Typography } from '@branch-messenger/willow-ui'
import { ArrowLeft } from '@branch-messenger/willow-ui/icons'
import { FC, MouseEvent } from 'react'

import { useAppNavigate } from '@/hooks/useAppNavigate'

import { StyledBackButton, StyledBackButtonContainer } from './styles'
import { ContentCardBackButtonProps } from './types'

export const ContentCardBackButton: FC<ContentCardBackButtonProps> = ({
  onClick,
  backRoute,
  params,
  search,
  title,
}) => {
  const navigate = useAppNavigate()

  const handleBackClick = (e: MouseEvent<HTMLButtonElement>) => {
    backRoute &&
      void navigate({ to: backRoute, params, ...(search && { search }) })
    onClick?.(e)
  }

  return (
    <StyledBackButtonContainer>
      <Box $display="flex">
        <StyledBackButton onClick={handleBackClick}>
          <Icon Icon={ArrowLeft} size={6} />
        </StyledBackButton>
      </Box>
      {!!title && (
        <Box $display="flex" $justify="center">
          <Typography $color="textMuted" $size="sm" $italicized>
            {title}
          </Typography>
        </Box>
      )}
    </StyledBackButtonContainer>
  )
}
