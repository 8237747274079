import { createFileRoute } from '@tanstack/react-router'

import { getWorkerOtpInfoQueryOptions } from '@/queryOptions/workers'

export const Route = createFileRoute('/_initialized/verificationSelection')({
  loaderDeps: ({ search: { worker_id } }) => ({ worker_id }),
  loader: ({ context: { queryClient }, deps: { worker_id } }) =>
    queryClient.ensureQueryData(
      getWorkerOtpInfoQueryOptions({ worker_id: worker_id! })
    ),
})
