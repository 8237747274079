import { Card, Typography } from '@branch-messenger/willow-ui'
import { ErrorComponentProps } from '@tanstack/react-router'
import { FC } from 'react'

import { StyledAppLayout } from './styles'

export const AppError: FC<ErrorComponentProps> = ({ error }) => {
  return (
    <StyledAppLayout $justify="center">
      <Card $display="flex" $direction="column" $align="initial" $gap={2}>
        <Typography $size="lg">
          An error occurred. Please try again later.
        </Typography>
        {!!error?.message && (
          <Typography $size="sm" $color="textMuted">
            {error.message}
          </Typography>
        )}
      </Card>
    </StyledAppLayout>
  )
}
