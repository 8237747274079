import { NavigateOptions, useNavigate } from '@tanstack/react-router'

import { router } from '@/lib/router'

import { useGetPersistedSearch } from './useGetPersistedSearch'

/**
 *
 * Custom navigation that sets replace to true and appends the current search params
 * We need to replace routes since this application will be embedded in an iframe. This will prevent the browser back button from navigating the iframe.
 *
 */
export const useAppNavigate = () => {
  const navigate = useNavigate()
  const getPersistedSearch = useGetPersistedSearch()
  const appNavigate = ({ search, ...restOptions }: NavigateOptions) => {
    return navigate({
      search: getPersistedSearch(search),
      replace: true,
      ...restOptions,
    })
  }

  return appNavigate
}

export const appNavigate = (options: NavigateOptions) => {
  return router.navigate({ search: router.state.location.search, ...options })
}
