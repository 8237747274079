import { ToastProvider } from '@branch-messenger/willow-ui'

import { useToastStore } from '@/store/toast'

import { Toast } from './Toast'

export const Toaster = () => {
  const toasts = useToastStore(state => state.toasts)
  return (
    <ToastProvider swipeDirection="right">
      {toasts.map(toast => {
        return <Toast key={toast.id} {...toast} />
      })}
    </ToastProvider>
  )
}
