import {
  AlertIcon,
  AlertProps,
  AlertTitle,
  DetailedAlertProps,
} from '@branch-messenger/willow-ui'
import { forwardRef } from 'react'

import { StyledContentCardFooterAlert } from './styles'

export const ContentCardFooterAlert = forwardRef<
  HTMLDivElement,
  Omit<AlertProps, '$type' | '$rounded'> & Pick<DetailedAlertProps, 'title'>
>(({ title, ...props }, ref) => {
  return (
    <StyledContentCardFooterAlert {...props} ref={ref}>
      <AlertIcon type="secondary" iconVariant="outlined" />
      <AlertTitle>{title}</AlertTitle>
    </StyledContentCardFooterAlert>
  )
})

ContentCardFooterAlert.displayName = 'ContentCardFooterAlert'
