import { Box } from '@branch-messenger/willow-ui'
import styled, { css } from 'styled-components'

import { mobileStyles, tabletAndUp } from '../sharedStyles'

export const StyledAppLayout = styled(Box).attrs({
  $p: 4,
  $display: 'flex',
  $direction: 'column',
  $gap: 4,
})`
  height: 100svh;

  background-color: ${({ theme }) => theme.colors.background};
  ${tabletAndUp(css`
    min-height: 850px;
  `)}
  ${mobileStyles(css`
    background-color: ${({ theme }) => theme.colors.surface};
    padding: 0;
  `)}
`

export const StyledAppBody = styled(Box).attrs({
  $justify: 'center',
  $display: 'flex',
  $direction: 'column',
  $flex: 1,
  $gap: 6,
  $fullWidth: true,
})`
  overflow: hidden;
  ${mobileStyles(css`
    justify-content: initial;
  `)}
`

export const StyledAppHeaderContainer = styled(Box).attrs({
  $display: 'flex',
  $fullWidth: true,
  $gap: 4,
})`
  ${mobileStyles(css`
    display: none;
  `)}
`

export const StyledAppFooterContainer = styled(Box).attrs({
  $display: 'flex',
  $justify: 'center',
})`
  ${mobileStyles(css`
    display: none;
  `)}
`

export const StyledBrandContainer = styled(Box).attrs({
  $display: 'flex',
  $gap: 2,
})`
  ${mobileStyles(css`
    display: none;
  `)}
`
