import { http } from '../http'
import {
  CheckUserWalletExistsPayload,
  CheckUserWalletExistsResponse,
  GetWorkerIdParams,
  GetWorkerIdResponse,
  GetWorkerOrgsPayload,
  GetWorkerOrgsResponse,
  GetWorkerPayload,
  InitializeOnboardingSessionPayload,
  SendOTPForNewVerificationMethodPayload,
  SendOTPPayload,
  VerifyOTPForNewVerificationMethodPayload,
  VerifyOTPPayload,
  VerifyOTPResponse,
  WorkerInfo,
  WorkerOnboardingStateResponse,
  WorkerOTPInfo,
} from './types'

export const initializeOnboardingSession = ({
  org_id,
}: InitializeOnboardingSessionPayload) => http.put(`/v1/self`, { org_id })

export const getWorkerId = (params: GetWorkerIdParams) =>
  http.get<GetWorkerIdResponse>('/v1/workers/id', { params })

export const getWorkerOTPInfo = ({ worker_id }: GetWorkerPayload) =>
  http.get<WorkerOTPInfo>(`/v1/workers/${worker_id}/otp_info`)

export const getWorkerOnboardingState = () =>
  http.get<WorkerOnboardingStateResponse>('/v1/self/state')

export const getWorkerInfo = () => http.get<WorkerInfo>('/v1/self/worker')

export const sendOTP = ({ worker_id, ...requestPayload }: SendOTPPayload) =>
  http.post(`/v1/workers/${worker_id}/verifications/otp`, { ...requestPayload })

export const verifyOTP = ({ worker_id, ...requestPayload }: VerifyOTPPayload) =>
  http.put<VerifyOTPResponse>(`/v1/workers/${worker_id}/verifications/otp`, {
    ...requestPayload,
  })

export const sendOTPForNewVerificationMethod = (
  payload: SendOTPForNewVerificationMethodPayload
) => http.post('/v1/verifications', payload)

export const verifyOTPForNewVerificationMethod = (
  payload: VerifyOTPForNewVerificationMethodPayload
) => http.put<VerifyOTPResponse>('/v1/verifications', payload)

export const checkUserWalletExists = (payload: CheckUserWalletExistsPayload) =>
  http.post<CheckUserWalletExistsResponse>(
    '/v1/accounts/branch/users/wallet',
    payload
  )

export const getWorkerOrgs = (payload: GetWorkerOrgsPayload) =>
  http.post<GetWorkerOrgsResponse>(
    '/v1/accounts/branch/users/organizations',
    payload
  )
