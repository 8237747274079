import { createFileRoute, redirect } from '@tanstack/react-router'

export const Route = createFileRoute('/_initialized')({
  beforeLoad: ({ context: { appStore } }) => {
    const isInitialized = appStore.getState().initialized
    if (!isInitialized) {
      redirect({ to: '/', throw: true, search: true })
    }
  },
})
