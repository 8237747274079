import { AlertDialog } from '@branch-messenger/willow-ui'

import { useAlertStore } from '@/store/alert'

export const GlobalAlert = () => {
  const { alert, removeAlert } = useAlertStore(state => state)
  if (!alert) return null
  return (
    <AlertDialog
      open={!!alert}
      onOpenChange={open => !open && removeAlert()}
      {...alert}
    />
  )
}
