export const spinnerSizes = {
  xs: '16px',
  sm: '20px',
  md: '40px',
  lg: '60px',
} as const

export type SpinnerProps = {
  $size?: keyof typeof spinnerSizes
}
