import { css, RuleSet } from 'styled-components'

export const mobileStyles = (style: RuleSet) => css`
  @media screen and (max-width: 600px) {
    ${style}
  }
`
export const tabletAndUp = (style: RuleSet) => css`
  @media screen and (min-width: 601px) {
    ${style}
  }
`
