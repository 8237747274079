import { queryOptions } from '@tanstack/react-query'

import { getInternalOrganization } from '@/api/organizations/organizations'

export const ORG_QUERY_KEY = 'ORG_QUERY_KEY'

export const orgQueryOptions = (org_id: string) =>
  queryOptions({
    queryKey: [ORG_QUERY_KEY, org_id],
    queryFn: async () => {
      const response = await getInternalOrganization(org_id)
      return {
        ...(response.data || {}),
        app_identifier: response.data?.settings?.app_identifier || 'Branch',
      }
    },
    staleTime: Infinity,
    gcTime: Infinity,
  })
