import { FC } from 'react'

import { useContentCardContext } from './hooks/useContentCardContext'
import {
  StyledContentCardFooter,
  StyledContentCardFooterContainer,
} from './styles'
import { ContentCardFooterProps } from './types'

export const ContentCardFooter: FC<ContentCardFooterProps> = ({ children }) => {
  const { contentOverflow } = useContentCardContext()
  return (
    <StyledContentCardFooterContainer>
      <StyledContentCardFooter $showShadow={contentOverflow}>
        {children}
      </StyledContentCardFooter>
    </StyledContentCardFooterContainer>
  )
}
