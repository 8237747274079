import {
  ComponentPropsWithoutRef,
  createContext,
  ElementRef,
  forwardRef,
  useState,
} from 'react'
import { WebTarget } from 'styled-components'

import { StyledContentCard } from './styles'

export type ContentCardContext = {
  contentOverflow: boolean
  setContentOverflow: (contentOverflow: boolean) => void
}

export const ContentCardContext = createContext<ContentCardContext | null>(null)

export const ContentCard = forwardRef<
  ElementRef<typeof StyledContentCard>,
  ComponentPropsWithoutRef<typeof StyledContentCard> & { as?: WebTarget }
>(({ children, ...props }, ref) => {
  const [contentOverflow, setContentOverflow] = useState<boolean>(false)

  return (
    <ContentCardContext.Provider
      value={{
        contentOverflow,
        setContentOverflow,
      }}
    >
      <StyledContentCard {...props} ref={ref}>
        {children}
      </StyledContentCard>
    </ContentCardContext.Provider>
  )
})

ContentCard.displayName = 'ContentCard'
