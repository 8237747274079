import { UsState } from '@/lib/usStates'

export type WorkerPhone = {
  last_digits: string
}
export type WorkerEmail = {
  first_chars: string
  domain: string
}
export type WorkerOTPInfo = {
  phone: WorkerPhone | null
  email: WorkerEmail | null
}

export type WorkerAddress = {
  address_1: string
  address_2?: string
  city: string
  state: UsState
  zip: string
}

export type WorkerInfo = {
  id: string
  first_name: string
  last_name: string
  dob?: string
  address?: WorkerAddress
  phone?: string
  email?: string
}

export type GetWorkerPayload = {
  worker_id: string
}

export const VerificationTypes = {
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
} as const

export type VerificationType =
  (typeof VerificationTypes)[keyof typeof VerificationTypes]

export type SendOTPPayload = {
  worker_id: string
  type: VerificationType
}

export type VerifyOTPPayload = {
  worker_id: string
  type: VerificationType
  code: string
}

export type SendOTPForNewVerificationMethodPayload = {
  destination: string
  channel: VerificationType
}

export type VerifyOTPForNewVerificationMethodPayload = {
  destination: string
  code: string
  channel: VerificationType
}

type Verification = {
  type: VerificationType
  destination: string
}

type StepValues = 'ORG_PASSCODE' | 'WORKER_PASSCODE' | 'OTP' | 'SSO' | 'NONE'

export type VerifyOTPResponse = {
  track_id: string
  org_id: string
  worker_id: string
  verifications: Verification[]
  completed_steps: StepValues[]
  token: string
  iat: number
  exp: number
}

export type WorkerOnboardingStateResponse = {
  org_id: string
  worker_id: string
  cur_step: StepValues
  verifications: Verification[]
}

export type InitializeOnboardingSessionPayload = {
  org_id: string
}

export type GetWorkerIdParams = {
  external_worker_id?: string
  phone?: string
  email?: string
}

export type GetWorkerIdResponse = {
  worker_id: string
}

export type CheckUserWalletExistsPayload = {
  phone: string
}

export type CheckUserWalletExistsResponse = {
  token: string
  has_wallet: boolean
}

export type GetWorkerOrgsPayload = {
  phone: string
}

export type GetWorkerOrgsResponse = {
  app_display_name: string
  organization_names: string[]
}
