import { createFileRoute } from '@tanstack/react-router'

import { getWorkerOrgsQueryOptions } from '@/queryOptions/workers'

export const Route = createFileRoute('/_initialized/linkAccount')({
  loader: async ({ context: { createWalletFormStore, queryClient } }) => {
    const phone = createWalletFormStore.getState().phone
    if (phone) {
      return queryClient.ensureQueryData(getWorkerOrgsQueryOptions({ phone }))
    }
  },
})
