import { Box } from '@branch-messenger/willow-ui'
import { FC } from 'react'

import { Spinner } from '../loader'
import { ContentCard } from '.'

export const ContentCardLoading: FC = () => {
  return (
    <ContentCard>
      <Box $display="flex" $justify="center" $flex={1}>
        <Spinner />
      </Box>
    </ContentCard>
  )
}
