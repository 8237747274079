import { QueryClient } from '@tanstack/react-query'

import { getWorkerId } from '@/api/workers'
import { getWorkerOtpInfoQueryOptions } from '@/queryOptions/workers'

import { RootSearchSchema } from './searchSchemas/rootSearchSchema'

export const getTranslatedWorkerOTPInfo = async (
  searchParams: RootSearchSchema,
  queryClient: QueryClient
) => {
  const searchParamsCopy = { ...searchParams }
  const { external_worker_id } = searchParamsCopy
  if (external_worker_id) {
    const workerData = await getWorkerId({ external_worker_id })
    searchParamsCopy.worker_id = workerData.data.worker_id
    searchParamsCopy.external_worker_id = undefined
  }
  const workerOtpInfo = await queryClient.fetchQuery(
    getWorkerOtpInfoQueryOptions({ worker_id: searchParamsCopy.worker_id! })
  )
  return { workerOtpInfo, translatedSearchParams: searchParamsCopy }
}
