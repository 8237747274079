import * as Sentry from '@sentry/react'

import { router } from '@/lib/router'

export const initializeSentry = () => {
  if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [Sentry.tanstackRouterBrowserTracingIntegration(router)],
      tracesSampleRate: 0.8,
      // disable replays for now
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,
    })
  }
}
