import Cookies from 'js-cookie'

/**
 * Cookie functions to be used in development only.
 * Workaround for cookies not being set from server on localhost.
 */
const tokenCookieKey = 'token'

export const setTokenCookie = (val: string): string => {
  // replace Bearer if it exists in the string
  const token = val.replace('Bearer ', '')
  Cookies.set(tokenCookieKey, token, {
    secure: false,
    sameSite: 'strict',
  })
  return val
}

export const getTokenCookie = (): string | undefined => {
  return Cookies.get(tokenCookieKey)
}

export const removeTokenCookie = (): void => {
  return Cookies.remove(tokenCookieKey)
}
