import { v4 as uuidv4 } from 'uuid'
import { create } from 'zustand'

import { AddToastParams, ToastStore } from './toast.types'

export const useToastStore = create<ToastStore>((set, get) => ({
  toasts: [],
  addToast: (addToastParams: AddToastParams) => {
    const toasts = get().toasts
    const toastId = uuidv4()

    toasts.push({ id: toastId, ...addToastParams })
    set(state => ({ ...state, toasts }))
    return toastId
  },
  removeToast: (id: string) => {
    const toasts = get().toasts.filter(toast => toast.id !== id)
    set(state => ({ ...state, toasts }))
  },
}))
