import { Box, Toast as WillowToast } from '@branch-messenger/willow-ui'
import { FC } from 'react'

import { useToastStore } from '@/store/toast'

import { ContactSupportLink } from './ContactSupportLink'
import { ToastProps } from './types'

export const Toast: FC<ToastProps> = ({
  variant = 'info',
  title,
  description,
  id,
}) => {
  const removeToast = useToastStore(state => state.removeToast)
  const closeToast = () => removeToast(id)
  const isAlert = variant === 'alert'

  return (
    <WillowToast
      title={title}
      description={
        <Box $display="flex" $direction="column" $gap={1} $align="flex-start">
          {description}
          {isAlert && <ContactSupportLink />}
        </Box>
      }
      variant={variant}
      onOpenChange={closeToast}
      data-testid={`toast-${variant}`}
    />
  )
}
