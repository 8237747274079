import {
  formatMaskedPhoneNumber,
  parsePhoneNumber,
} from '@branch-messenger/willow-ui'
import { create } from 'zustand'

import { CreateWalletForm } from './createWalletForm.types'

export const useCreateWalletFormState = create<CreateWalletForm>(set => ({
  address: undefined,
  date_of_birth: undefined,
  passcode: undefined,
  phone: undefined,
  setWalletFormState: newState => set(newState),
}))

export const useParsedPhoneNumber = () => {
  const phone = useCreateWalletFormState(state => state.phone)
  return parsePhoneNumber(phone || '', { defaultCountry: 'US' })
}

export const useMaskedPhoneNumber = () => {
  const phone = useCreateWalletFormState(state => state.phone)
  return formatMaskedPhoneNumber(phone || '')
}
