import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'

import { getTokenCookie, removeTokenCookie, setTokenCookie } from '@/lib/token'

export const branchHttp = axios.create({
  baseURL: import.meta.env.VITE_BRANCH_API_URL,
  timeout: 3600000,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json' },
})

const onRequestFulfilled = (config: InternalAxiosRequestConfig) => {
  if (import.meta.env.DEV) {
    const token = getTokenCookie()
    if (token) config.headers.Authorization = `Bearer ${token}`
  }
  return config
}

const onResponseFulFilled = (res: AxiosResponse) => {
  if (res.headers && res.headers.authorization && import.meta.env.DEV) {
    setTokenCookie(res.headers.authorization as string)
  }
  return res
}

const onRequestError = (err: AxiosError) => Promise.reject(err)

const onResponseError = (err: AxiosError) => {
  if (err && err.response && err.response.status === 401) {
    removeTokenCookie()
  }
  return onRequestError(err)
}

branchHttp.interceptors.request.use(onRequestFulfilled, onRequestError)
branchHttp.interceptors.response.use(onResponseFulFilled, onResponseError)
