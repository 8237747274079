import { zodSearchValidator } from '@tanstack/router-zod-adapter'
import { z } from 'zod'

const schema = z.object({
  acceptedStoragePermissions: z.boolean().optional(),
  acknowledgedStoragePermissionsPopup: z.boolean().optional(),
})

export const homeSearchSchema = zodSearchValidator(schema)
export type HomeSearchSchema = z.infer<typeof schema>
