import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query'
import { useSearch } from '@tanstack/react-router'

import { VerifyOrgPasscodePayload } from '@/api/organizations'
import { verifyOrgPasscode } from '@/api/organizations'
import { useAppNavigate } from '@/hooks/useAppNavigate'
import { getTranslatedWorkerOTPInfo } from '@/lib/getTranslatedWorkerOTPInfo'
import { orgQueryOptions } from '@/queryOptions/organizations'
import { useAlertStore } from '@/store/alert'

export const useOrg = () => {
  const { org_id } = useSearch({ strict: false })
  return useSuspenseQuery(orgQueryOptions(org_id!))
}

export const useVerifyOrgPasscodeMutation = () => {
  const searchParams = useSearch({ from: '__root__' })

  const navigate = useAppNavigate()
  const showAlert = useAlertStore(state => state.showAlert)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: VerifyOrgPasscodePayload) =>
      verifyOrgPasscode(payload),
    onSuccess: async () => {
      try {
        const { translatedSearchParams, workerOtpInfo } =
          await getTranslatedWorkerOTPInfo(searchParams, queryClient)
        const { email, phone } = workerOtpInfo

        if (!email && !phone) {
          return navigate({
            to: '/missingAccountInfo',
            search: translatedSearchParams,
          })
        }
        return navigate({
          to: '/terms',
          search: translatedSearchParams,
        })
      } catch (error) {
        showAlert({
          title: 'Error getting worker info.',
          description:
            'Please try again or reach out to your manager for assistance.',
          hideCancel: true,
        })
      }
    },
    onError: () => {
      showAlert({
        title: 'Incorrect passcode.',
        description:
          'Please check your spelling or reach out to your manager for the correct passcode.',
        hideCancel: true,
      })
    },
  })
}
