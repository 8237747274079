import { Box, Icon, Typography } from '@branch-messenger/willow-ui'
import {
  BankNoteDown,
  CalendarDots,
  CurrencyCrossedOut,
  Lightning01,
} from '@branch-messenger/willow-ui/icons'

import DebitCardIllustration from '@/assets/illustrations/debit-card-outline.svg?react'
import {
  ContentCard,
  ContentCardBody,
  ContentCardButton,
  ContentCardFooter,
  ContentCardHeading,
} from '@/components/contentCard'
import { useAppNavigate } from '@/hooks/useAppNavigate'
import { useOrg } from '@/queries/organizations'

const infoList = [
  {
    icon: Lightning01,
    text: 'Direct access to earnings',
  },
  {
    icon: CurrencyCrossedOut,
    text: 'No banking or overdraft fees',
  },
  {
    icon: BankNoteDown,
    text: 'Instant access to your cash',
  },
  { icon: CalendarDots, text: 'Up to 2 day early direct deposit' },
]

export const CardInfo = () => {
  const navigate = useAppNavigate()
  const {
    data: {
      settings: { app_identifier },
    },
  } = useOrg()
  return (
    <ContentCard>
      <ContentCardBody>
        <Box $display="flex" $justify="center" $fullWidth>
          <DebitCardIllustration />
        </Box>
        <ContentCardHeading subtitle="Enjoy no fees, cash back rewards, and instant access in the app to start using your card right away.">
          {`Next, let’s order your free ${app_identifier} debit card.`}
        </ContentCardHeading>
        <Box
          $display="flex"
          $direction="column"
          $align="start"
          $fullWidth
          $gap={6}
        >
          {infoList.map(({ icon, text }) => (
            <Box $display="flex" $align="center" $gap={4} key={text}>
              <Icon Icon={icon} size={6} />
              <Typography>{text}</Typography>
            </Box>
          ))}
        </Box>
      </ContentCardBody>
      <ContentCardFooter>
        <ContentCardButton
          onClick={() =>
            navigate({
              to: '/terms',
              search: { agreementType: 'essential' },
            })
          }
        >
          Continue
        </ContentCardButton>
      </ContentCardFooter>
    </ContentCard>
  )
}
