import styled, { keyframes } from 'styled-components'

import { SpinnerProps, spinnerSizes } from './types'

const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
})

export const Spinner = styled.svg<SpinnerProps>`
  border: 5px solid ${({ theme }) => theme.colors.gray};
  border-top: 5px solid ${({ theme }) => theme.colors.selection};
  border-radius: ${({ theme }) => theme.radii.rounded};
  animation: ${spin} 1s linear infinite;
  width: ${({ $size = 'md' }) => spinnerSizes[$size]};
  height: ${({ $size = 'md' }) => spinnerSizes[$size]};
`
